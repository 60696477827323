<template>
    <div class="languagepicker">
        <!-- <Header :languagepick="true"/> -->
    <b-container class="lang-container">
        <b-row class="lang-row" align-v="center">
            <b-col class="col-pad"><button type="button" @click="pickLanguage('nl')" class="language-btn btn btn-secondary btn-lg">NL sessie - 11u</button></b-col>
            <b-col class="col-pad"><button type="button" @click="pickLanguage('fr')" class="language-btn btn btn-secondary btn-lg push-right">session FR - 13h</button></b-col>
        </b-row>
    </b-container>
    </div>
</template>
<script>
export default {
  name: "LanguagePick",
  components: {
    // Header,
  },
  computed: {
      isLoggedIn() {
          return this.$store.getters["Auth/isLoggedIn"]
      }
  },
  methods: {
      pickLanguage(lang) {
        if (lang == "nl") {
            localStorage.setItem("lang", lang)
            this.$i18n.locale = localStorage.getItem("lang")
            this.$i18n.fallbackLocale = localStorage.getItem("lang")
            
        } else if (lang == "fr") {
            localStorage.setItem("lang", lang)
            this.$i18n.locale = localStorage.getItem("lang")
            this.$i18n.fallbackLocale = localStorage.getItem("lang")
        }
        this.$router.push("/home")
      }
  }
}
</script>

<style>
    .languagepicker {
        flex-grow: 1;
    }
    .language-buttons {
       position: absolute;
       width: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .lang-container {
        min-height: 100%;
    }
    .lang-row {
        position: absolute;
        top: 50%;
        width: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .col-pad {
        padding: 60px;
    }
    .language-btn {
        height: 100px;
        width: 100%;
        font-size: 25px;
        font-weight: 400;
    }
    @media only screen and (max-width: 525px) {
        .col-pad {
            padding:10px;
        }
        .language-btn {
            height: 100px;
            font-size: 20px;
        }
    }
</style>